<style lang="scss">
.martkplatz-mobilitaet {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .custom-link {
    color: $blue-dark !important;
    font-weight: 500;
  }
  .custom-link-v2 {
    font-weight: 500;
    background: $blue-dark;
    color: #fff !important;
    padding: 10px 15px;
    display: inline-block;
    &:hover {
      opacity: 0.7;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="martkplatz-mobilitaet content container">
    <h1>Mobilität</h1>
    <p class="intro-txt">
      Ob es um Neuheiten aus der Auto- &amp; Motorwelt, einem Autoverkauf bzw. -kauf oder einfach nur um praktische Tipps rund um Autos geht: Die Kleine Zeitung beleuchtet das facettenreiche Thema „Mobilität“ aus vielen Perspektiven und schafft Umfelder voll mit Storys, Trends und Servicegeschichten. Platzieren Sie Ihre Anzeige im reichweitenstärksten Auto- & Motor-Markt im Süden Österreichs. Der
      Mobilitäts-Teil der Kleinen Zeitung erscheint jeden Freitag (Hauptmarkt) und Sonntag (Zweitmarkt).
    </p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/moblitaet.jpg" alt="Wohnen" class="img-fluid" />
    </div>

    <h2>Individuelle Werbelösungen</h2>
    <p>Nutzen Sie unser umfangreiches Portfolio, um Ihr Werbeziel optimal zu erreichen.</p>

    <TabGroup>
      <Tab tabid="moblitaet-tab1" :title="'Raum- und Zeilenanzeigen'" active>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h3>Produktdaten</h3>
            <p>Nutzen Sie den Marktplatz „Mobilität“ der Kleinen Zeitung. Dieser erscheint jeden Freitag (Hauptmarkt) und Sonntag (Zweitmarkt) in der Kleinen Zeitung.</p>
            <p><span class="hinweis-headline">NEU</span><br />Der Mobilitäts-Teil der Kleinen Zeitung erscheint 10 x im Jahr (Februar bis November) am letzten Freitag im Monat als umfangreichere Beilage (Supplement).</p>
            <br />
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Hauptblatt): </strong><br />
              Donnerstag, 10 Uhr / Donnerstag, 11 Uhr für Freitag<br />
              Dienstag, 10 Uhr / Dienstag, 11 Uhr für Mittwoch
            </p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Beilage Kleinanzeigenteil):</strong><br />
              Mittwoch, 18 Uhr
            </p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Beilage redaktioneller Teil): </strong><br />
              Freitags vor Erscheinung. 11 Uhr / Mittwoch 18 Uhr
            </p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/mobilitaet-bsp-seiten.png" alt="Mobilität" class="img-fluid" />
          </div>
        </div>
        <br />

        <!-- -->
        <div class="row">
          <div class="col-lg-12 justify-content-center align-self-center order-lg-2">
            <div class="tabelle-box tabelle-width-50">
              <div class="headline">
                <strong>Millimeterpreis 4C</strong>
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">20,68</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">14,83</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9,07</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">21,88</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">15,48</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9,47</div>
              </div>
            </div>
          </div>
          <!--<div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/prod-mobilitaet.png" alt="Mobilität" class="img-fluid" />
          </div>-->
        </div>

        <h3>Raumanzeigen</h3>
        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/8 Seite</strong>
                Fixformat: 47 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">2.791,80</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">2.002,05</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">1.224,45</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">2.953,80</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">2.089,80</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">1.278,45</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/4 Seite</strong>
                Fixformat: 98 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">5.583,60</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">4.004,10</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">2.448,90</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">5.907,60</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">4.179,60</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">2.556,90</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/2 Seite</strong>
                Fixformat: 200 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">11.167,20</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">8.008,20</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">4.897,80</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">11.815,20</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">8.359,20</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">5.113,80</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/1 Seite</strong>
                Fixformat: 200 x 275 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MO - FR</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">22.748,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">16.313,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9.977,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA - SO</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">24.068,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">17.028,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">10.417,00</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>Rubrikenvorspann</strong>
                Format: 200 x 110 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MI</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">1.990,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">1.500,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">900,00</div>
              </div>
              <div class="info-txt-tabelle">Erscheinung nur mittwochs möglich. <br />Anzeigenschluss Freitag 10 Uhr vor Erscheinungstermin. <br />Pro Mittwochausgabe nur ein Rubrikenvorspann verfügbar.</div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x110.svg" alt="200x110" class="img-fluid " />
          </div>
        </div>

        <h3>Zeilenanzeigen</h3>
        <p>Ihre Möglichkeit, eine Zeilenanzeige im seriösen und reichweitenstarken Auto- &amp; Motor-Markt im Süden Österreichs zu platzieren</p>
        <div class="row">
          <div class="col-lg-8 justify-content-center align-self-center">
            <div class="tabelle-box alignItems">
              <div class="headline">
                <strong>Zeilenanzeigen </strong>
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Premium-Anzeigen</span> (inkludiert Farbbild, 4 Zeilen Text, zweizeiliges Logo und Preiszeile)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">162,01</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">106,37</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">86,26</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Basis-Anzeigen</span> (inkludiert 4 Zeilen Text und Preiszeile)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">63,82</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">40,62</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">30,58</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Jede weitere Zeile</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">11,82</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">7,56</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">5,64</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">16,48</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">13,01</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9,54</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Farbbild</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">65,23</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">39,73</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">36,60</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Icon</span> je Icon (max. 3)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">3,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">3,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">3,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Willhaben-Code</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">5,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">5,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">5,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Wiederholung</span> (Zweitmarkt-Schaltmöglichkeit am Sonntag)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/bsp-mobilitaet.png" alt="Bsp. Mobilität" class="img-fluid" />
          </div>
        </div>
      </Tab>
      <Tab tabid="moblitaet-tab2" :title="'Auto-Karussell'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Verstärken Sie Ihre Werbebotschaft mit Display-Werbung auf www.kleinezeitung.at und erreichen Sie latent Suchende aus Ihrer Region durch Zielgruppen-Targeting. Ein Werbemittel – mehrere Fahrzeuge!</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/moblitaet-autokarussell.jpg" alt="Autokarusell" class="img-fluid" />
            </div>
          </div>
        </div>
        <h3>Leistungen &amp; Tarife</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li>Latent interessierte UserInnnen aus der Region erreichen</li>
              <li>Mehrere Fahrzeuge in eineme Werbemittel</li>
              <li>Regionales Zielgruppen-Targeting</li>
              <li>Günstiger TKP durch 12 Monatsbindung</li>
            </ul>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="tabelle-box alignItems">
              <div class="row head-row hide-small head-row-text-normal">
                <div class="col-lg-4 col-6 text-align-left"></div>
                <div class="col-lg-4 col-6 text-align-right">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right"><span class="text-normal">Als</span>/Monat</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small">bis 5 Fahrzeuge</div>
                <div class="col-6 text-align-left visible-small">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right">79,00</div>
                <div class="col-6 text-align-left visible-small">Als/Monat</div>
                <div class="col-lg-4 col-6 text-align-right">8.400</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small">bis 20 Fahrzeuge</div>
                <div class="col-6 text-align-left visible-small">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right">149,00</div>
                <div class="col-6 text-align-left visible-small">Als/Monat</div>
                <div class="col-lg-4 col-6 text-align-right">17.000</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small">bis 50 Fahrzeuge</div>
                <div class="col-6 text-align-left visible-small">Monatspreis</div>
                <div class="col-lg-4 col-6 text-align-right">199,00</div>
                <div class="col-6 text-align-left visible-small">Als/Monat</div>
                <div class="col-lg-4 col-6 text-align-right">25.200</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
        <div class="info-txt-preis">Bei Nicht-Kündigung verlängert sich die Laufzeit auf 12 Monate zum angegebenen Monatspreis.</div>
      </Tab>
      <Tab tabid="moblitaet-tab4" :title="'willhaben.at'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Profitieren Sie von der optimalen Kombination „Kleine Zeitung“ und „willhaben“. Mit der reichweitenstärksten Tageszeitung im Süden Österreichs und dem größten digitalen Marktplatz des Landes nutzen Sie das erfolgreichste Kommunikationsnetzwerk Österreichs.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/klz-willhaben.jpg" alt="KLZ + Willhaben" class="img-fluid" />
            </div>
          </div>
        </div>
        <h3>Ihre Werbemöglichkeiten als Add-On zur Kleinen Zeitung.</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li>Content-Ad 300 x 250 px</li>
              <li>run over vertical</li>
              <li>Anzeigendetailansicht</li>
              <li>C2C-Bereich</li>
              <li>Laufzeit 4 Wochen</li>
            </ul>

            <div class="tabelle-box alignItems">
              <div class="row head-row head-row-text-normal">
                <div class="col-lg-4 col-6 text-align-left hide-small"></div>
                <div class="col-lg-4 col-6 text-align-right text-left-small"><span class="text-normal">Als</span></div>
                <div class="col-lg-4 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Small</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">10.000</div>
                <div class="col-lg-4 col-6 text-align-right">140,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Medium</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">20.000</div>
                <div class="col-lg-4 col-6 text-align-right">260,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Large</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">30.000</div>
                <div class="col-lg-4 col-6 text-align-right">360,00</div>
              </div>
              <div class="info-txt-tabelle">Anforderungen ans Werbemittel: max. 150kb. Gängige Bildformate (jpg, png, gif etc.) oder HTML Werbemittel sind möglich (hier sind clicktags nach iab Standard einzubauen)</div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/mobilitaet-willhaben.jpg" alt="Moblität Laptop Willhaben" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer. Keine Rabatte möglich.</div>
      </Tab>
      <Tab tabid="moblitaet-tab5" :title="'KLEINE EXTRA'">
        <div class="row">
          <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
            <div class="responsive-video">
              <video autostart="false" controls="">
                <source src="https://assets.smd-digital.at/tarif-klz/videos/klzar_web_finale.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Was ist KLEINE EXTRA?</h3>
            <p>
              Mit <strong>KLEINE EXTRA</strong> erleben unsere ZeitungsleserInnen eine neue Dimension des Zeitungslesens. Die Kleine Zeitung wird Augmented Reality (kurz AR) – diese Technologie steckt hinter der KLEINE EXTRA-Funktion - ab sofort als Testlauf bei diversen redaktionellen Inhalten, Ihren Inseraten sowie an jedem letzten Freitag des Monats, für den Themenschwerpunkt Wohnen &amp;
              Mobilität EXTRA einsetzen und den LeserInnen damit wirklichkeitserweiternde Aha-Erlebnisse bescheren und das, indem Sie EXTRA-Inhalte wie 3D-Modelle, Videos und vieles mehr direkt auf der Zeitungsseite und auf Ihren Inseraten entdecken können. Diese sind mit einem eigenen Symbol gekennzeichnet und warten darauf gescannt zu werden.
            </p>
            <router-link :to="{ name: 'KleineExtra' }" class="custom-link-v2">Mehr Informationen finden Sie hier</router-link>
          </div>
        </div>
      </Tab>
    </TabGroup>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/marktplaetze/Mobilitaet-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Mobilität
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>668 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Mobilitaet',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
</script>
